import AccountForgotPassword from "pages/Account/ForgotPassword";
import AccountResetPassword from "pages/Account/ResetPassword";
import GetStarted from "pages/GetStarted";
import Login from "pages/Login";
import { RouteType } from "routes/types";
import { APP_URLS } from "settings";

const UNAUTHENTICATED_ROUTES: RouteType[] = [
  {
    path: APP_URLS.GET_STARTED.MAIN,
    component: GetStarted,
  },
  {
    path: APP_URLS.ACCOUNT.RESET_PASSWORD,
    component: AccountResetPassword,
  },
  {
    path: APP_URLS.ACCOUNT.FORGOT_PASSWORD,
    component: AccountForgotPassword,
  },
  {
    path: APP_URLS.LOGIN_MAGIC,
    component: Login,
  },
  {
    path: APP_URLS.LOGIN,
    component: Login,
  },
];

export default UNAUTHENTICATED_ROUTES;
