import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { MUTATION_UPDATE_ACCOUNT } from "api/mutations/account";

import { trackEvent } from "analytics";
import ActionCTA from "components/ActionCTA";
import ActionCTATitle from "components/ActionCTA/Title";
import CTAButton from "components/CTA/Button";
import FormInputCurrency from "components/Form/Input/Currency";
import { useNotificationStore } from "store/Notifications";
import { useUserStore } from "store/User";
import { inputToFloat } from "utils/currency";

type FormInputs = {
  householdIncome: string;
};

const schema = yup.object({
  householdIncome: yup.string().required("Please input your household income"),
});

export default function CaptureIncome() {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const createNotification = useNotificationStore(
    (state) => state.createNotification
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      householdIncome: "",
    },
  });

  const [
    updateAccount,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_ACCOUNT);

  /**
   * Handle submission errors
   */
  useEffect(() => {
    if (mutationData?.response?.customer) {
      setLoadingSubmit(true);
      setTimeout(() => {
        setUser(mutationData.response.customer);
        trackEvent("Dashboard: Income input");
        createNotification({
          title: "Household income",
          description: "Income saved, you can now view your equity options!",
        });
      }, 1000);
    }
  }, [mutationData, setUser, createNotification]);

  const error = errors?.householdIncome?.message
    ? errors.householdIncome.message
    : mutationError
    ? "Sorry, there was an error submitting your income. Please try again or contact us on 07769 469660."
    : null;

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const _householdIncome = data.householdIncome
      ? inputToFloat(data.householdIncome)
      : null;

    updateAccount({
      variables: {
        input: {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phoneNumber: user?.detail?.phoneNumber,
          dateOfBirth: user?.detail?.dateOfBirth,
          marketingOptin: user?.detail?.marketingOptin,
          householdIncome: _householdIncome,
        },
      },
    });
  };

  return (
    <ActionCTA name="Capture income" bookACall={false}>
      <>
        <ActionCTATitle title="Household income">
          <>£</>
        </ActionCTATitle>

        <p className="mt-3">
          Please enter an estimate of your annual household income so we can
          calculate your options:
        </p>
        <form className="mt-3" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormInputCurrency
            id="householdIncome"
            register={register}
            error={error}
          />
          <div className="mt-4 flex justify-end">
            <CTAButton
              type="submit"
              label="View my options"
              loading={mutationLoading || loadingSubmit}
            />
          </div>
        </form>
      </>
    </ActionCTA>
  );
}
