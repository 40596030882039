import {
  ArrowDownOnSquareStackIcon,
  ArrowTopRightOnSquareIcon,
  HomeIcon,
  LockClosedIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import ActionCTA from "components/ActionCTA";
import ActionCTATitle from "components/ActionCTA/Title";
import CTAButton from "components/CTA/Button";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS, CRM } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { EquityCheckType } from "ts/types/equitycheck";
import { asCurrency } from "utils/currency";

const productLoanValue = (productValue: number) =>
  `£${asCurrency({ value: productValue })}`;

/**
 * Condition to assert product can be rendered
 * @param productKey
 * @param productValue
 * @returns bool
 */
const canRenderProduct = (
  productKey: string,
  productValue: number | undefined,
  filter: string[]
) => {
  if (productValue) {
    if (filter.length === 0) {
      return true;
    }

    if (filter.includes(productKey)) {
      return true;
    }
  }

  return false;
};

function ProductActionCTA({
  name,
  bookingLink,
  title,
  copy,
  product,
  children,
}: {
  name: string;
  bookingLink?: string;
  title: string;
  copy: string;
  product: string;
  children: JSX.Element;
}) {
  return (
    <div className="shrink-0 basis-80">
      <ActionCTA name={name} bookingLink={bookingLink} product={`p_${product}`}>
        <div>
          <ActionCTATitle title={title}>{children}</ActionCTATitle>
          <p className="mt-4" dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
      </ActionCTA>
    </div>
  );
}

function ProductActionPageCTA({
  name,
  title,
  copy,
  product,
  pageRoute,
  children,
}: {
  name: string;
  bookingLink?: string;
  title: string;
  copy: string;
  product: string;
  pageRoute?: string | Function;
  children: JSX.Element;
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!pageRoute) {
      return;
    }
    if (pageRoute instanceof Function) {
      return pageRoute();
    }
    return navigate(pageRoute);
  };

  return (
    <div className="shrink-0 basis-80">
      <ActionCTA name={name} bookACall={false} product={`p_${product}`}>
        <>
          <div>
            <ActionCTATitle title={title}>{children}</ActionCTATitle>
            <p className="mt-4" dangerouslySetInnerHTML={{ __html: copy }} />
          </div>
          {pageRoute && (
            <div className="w-full">
              <CTAButton
                label="Get Started >"
                styling="text-black border-none bg-brand-orange-medium hover:bg-brand-orange-bright"
                onClick={handleClick}
              />
            </div>
          )}
        </>
      </ActionCTA>
    </div>
  );
}

export function AutoRemortgageProducCTA({
  equityCheck,
  filter = [],
}: {
  equityCheck: EquityCheckType;
  filter: string[];
}) {
  const navigate = useNavigate();

  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  const hasPotentialSavings =
    equityCheck?.productAutoRemortgage?.potentialMonthlySavings &&
    equityCheck.productAutoRemortgage.potentialMonthlySavings > 0;

  const navigateToAutoRemortgage = () => {
    setRemortgageProgress({}, true);

    if (!hasPotentialSavings) {
      return navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS
        )
      );
    }

    return navigate(APP_URLS.PRODUCT_PAGES.REMORTGAGE.ROOT);
  };

  if (
    equityCheck.productAutoRemortgage &&
    equityCheck?.live?.propertyMortgagePercent > 0 &&
    canRenderProduct("productAutoRemortgage", 1, filter)
  ) {
    if (equityCheck?.productAutoRemortgage?.applicationSubmitted) {
      return (
        <ProductActionPageCTA
          name="Auto-Remortgage"
          product="auto_remortgage"
          title="Remortgage now"
          copy={`Your application to ${equityCheck?.productAutoRemortgage.applicationSubmitted.lenderName} on ${equityCheck?.productAutoRemortgage.applicationSubmitted.submittedOn} is being reviewed, we'll be in touch with any updates`}
        >
          <ReceiptPercentIcon className="h-8 w-8" />
        </ProductActionPageCTA>
      );
    }

    if (
      equityCheck?.productAutoRemortgage?.potentialMonthlySavings &&
      hasPotentialSavings
    ) {
      return (
        <ProductActionPageCTA
          name="Auto-Remortgage"
          product="auto_remortgage"
          title="Remortgage now"
          copy={`You can remortgage now and save up to <span class="font-bold">${productLoanValue(
            equityCheck?.productAutoRemortgage.potentialMonthlySavings
          )}</span> per month`}
          pageRoute={navigateToAutoRemortgage}
        >
          <ReceiptPercentIcon className="h-8 w-8" />
        </ProductActionPageCTA>
      );
    }

    return (
      <ProductActionPageCTA
        name="Auto-Remortgage"
        product="auto_remortgage"
        title="Remortgage now"
        copy="Mortgage deal ending? Let us find you the best deal and apply for it in under 5 minutes"
        pageRoute={navigateToAutoRemortgage}
      >
        <ReceiptPercentIcon className="h-8 w-8" />
      </ProductActionPageCTA>
    );
  }

  if (
    canRenderProduct("productRemortgage", equityCheck.productRemortgage, filter)
  ) {
    return (
      <ProductActionCTA
        name="Remortgage"
        product="remortgage"
        bookingLink={CRM.APPOINTMENT_BOOKING_URL_REMORTGAGE}
        title="Remortgage now"
        copy={`You can remortgage your current mortgage or borrow more, up to <span class="font-bold">${productLoanValue(
          equityCheck.productRemortgage
        )}</span>`}
      >
        <ReceiptPercentIcon className="h-8 w-8" />
      </ProductActionCTA>
    );
  }

  return <></>;
}

export default function ProductCTAs({
  equityCheck,
  filter = [],
}: {
  equityCheck: EquityCheckType;
  filter: string[];
}) {
  return (
    <>
      <AutoRemortgageProducCTA equityCheck={equityCheck} filter={filter} />

      {canRenderProduct(
        "productEquityRelease",
        equityCheck.productEquityRelease,
        filter
      ) && (
        <ProductActionCTA
          name="Equity Release"
          product="equity_release"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_EQUITY_RELEASE}
          title="Equity release"
          copy={`Get tax-free cash of up to <span class="font-bold">${productLoanValue(
            equityCheck.productEquityRelease
          )}</span> from your home equity. No mandatory monthly repayments.`}
        >
          <ArrowTopRightOnSquareIcon className="h-7 w-7" />
        </ProductActionCTA>
      )}

      {canRenderProduct(
        "productBuyProperty",
        equityCheck.productBuyProperty,
        filter
      ) && (
        <ProductActionCTA
          name="Buy Property"
          product="buy_property"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_BUY_PROPERTY}
          title="Buy a new property"
          copy={`You can use your equity to buy a new home or get a Buy To Let of up to <span class="font-bold">${productLoanValue(
            equityCheck.productBuyProperty
          )}</span>`}
        >
          <HomeIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productEquityLoan",
        equityCheck.productEquityLoan,
        filter
      ) && (
        <ProductActionCTA
          name="Equity Loan"
          product="equity_loan"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_EQUITY_LOAN}
          title="Get a loan"
          copy={`Get up to <span class="font-bold">${productLoanValue(
            equityCheck.productEquityLoan
          )}</span> from your home equity`}
        >
          <>£</>
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productDebtConsolidation",
        equityCheck.productDebtConsolidation,
        filter
      ) && (
        <ProductActionCTA
          name="Debt Consolidation"
          product="debt_consolidation"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_DEBT_CONSOLIDATION}
          title="Consolidate your debts"
          copy={`Use your home equity to consolidate debts of up to <span class="font-bold">${productLoanValue(
            equityCheck.productDebtConsolidation
          )}</span>`}
        >
          <ArrowDownOnSquareStackIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productMortgageProtection",
        equityCheck.productMortgageProtection,
        filter
      ) && (
        <ProductActionCTA
          name="Mortgage Protection"
          product="mortgage_protection"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL}
          title="Mortgage Protection"
          copy="Make sure you have tailored protection, e.g. income protection or critical illness cover"
        >
          <LockClosedIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
    </>
  );
}
