import moment from "moment";
import { IS_TEST_ENV } from "settings";
import { CommonObjectType } from "ts/types";

export type ContryConfigType = {
  IS_BETA: boolean;
  COUNTRY_CODE: string;
  CURRENCY_SYMBOL: string;
  SHOW_META_INFO: boolean;
  SHOW_SIGN_UP_PROGRESS: boolean;
  SHOW_DISCLAIMER: boolean;
  SHOW_CONTACT_LINK: boolean;
  SHOW_COOKIE_POLICY_LINK: boolean;
  CAN_BOOK_CALLS: boolean;
  CAN_ADDRESS_LOOKUP: boolean;
  LEAD_GENS: boolean;
  REQUIRE_PROPERTY_DETAILS: boolean;
  REQUIRE_CUSTOMER_TITLE: boolean;
  REQUIRE_PHONE_NUMBER: boolean;
  REQUIRE_SIGNUP_DATASET: boolean;
  ASK_LOAN_QUESTIONS: boolean;
  COPY: CommonObjectType;
  LINKS: CommonObjectType;
};

export type CountriesConfigType = {
  [countryCode: string]: ContryConfigType;
};

export const DEFAULT_COPY = {
  GET_STARTED:
    "We'll use this information to work out how much equity you have in your home.",
  SIGN_UP_DISCLAIMER:
    "By creating an account, you agree to Ahauz's [TERMS_LINK] and [PRIVACY_POLICY_LINK]. You also authorise Ahauz to perform a soft credit check from credit reference agencies. This will have NO impact on your credit score.",
  ADDRESS_LABELS: {
    LINE_1: "Address line 1",
    LINE_2: "Address line 2",
    LINE_3: "Address line 3",
    TOWN_CITY: "Town or City",
    COUNTY: "County",
    COUNTRY: "Country",
    POSTCODE: "Postcode",
  },
  VALUE_LABELS: {
    MORTGAGE_BALANCE: "Mortgage Balance",
  },
  COPYRIGHT: `Copyright © ${moment().year()} ${
    IS_TEST_ENV ? "Veilo" : "Ahauz Limited"
  }. All rights reserved.`,
};

export const COUNTRY_GREAT_BRITAIN: string = "GB";
export const CURRENCY_GREAT_BRITAIN: string = "£";
export const COUNTRY_INDIA: string = "IN";
export const CURRENCY_INDIA: string = "₹";

export const DEFAULT_COUNTRY: string = COUNTRY_GREAT_BRITAIN;
export const DEFAULT_CURRENCY: string = CURRENCY_GREAT_BRITAIN;

// export const DEFAULT_COUNTRY: string = COUNTRY_INDIA;
// export const DEFAULT_CURRENCY: string = CURRENCY_INDIA;

export const COUNTRY_CODES: string[] = [COUNTRY_GREAT_BRITAIN, COUNTRY_INDIA];

export const DEFAULT_CONFIG: ContryConfigType = {
  IS_BETA: false,
  COUNTRY_CODE: DEFAULT_COUNTRY,
  CURRENCY_SYMBOL: CURRENCY_GREAT_BRITAIN,
  SHOW_META_INFO: true,
  SHOW_SIGN_UP_PROGRESS: true,
  SHOW_DISCLAIMER: true,
  SHOW_CONTACT_LINK: true,
  SHOW_COOKIE_POLICY_LINK: true,
  CAN_BOOK_CALLS: true,
  CAN_ADDRESS_LOOKUP: true,
  LEAD_GENS: false,
  REQUIRE_PROPERTY_DETAILS: true,
  REQUIRE_CUSTOMER_TITLE: true,
  REQUIRE_PHONE_NUMBER: false,
  REQUIRE_SIGNUP_DATASET: false,
  ASK_LOAN_QUESTIONS: false,
  COPY: DEFAULT_COPY,
  LINKS: {
    TERMS: "https://ahauz.com/terms/",
    PRIVACY: "https://ahauz.com/privacy-policy/",
  },
};

export const COUNTRIES_CONFIG: CountriesConfigType = {
  [COUNTRY_GREAT_BRITAIN]: { ...DEFAULT_CONFIG, ...{} },
  [COUNTRY_INDIA]: {
    ...DEFAULT_CONFIG,
    ...{
      IS_BETA: true,
      COUNTRY_CODE: COUNTRY_INDIA,
      CURRENCY_SYMBOL: CURRENCY_INDIA,
      SHOW_META_INFO: false,
      SHOW_SIGN_UP_PROGRESS: false,
      SHOW_DISCLAIMER: false,
      SHOW_CONTACT_LINK: false,
      SHOW_COOKIE_POLICY_LINK: false,
      CAN_BOOK_CALLS: false,
      CAN_ADDRESS_LOOKUP: false,
      LEAD_GENS: false,
      REQUIRE_PROPERTY_DETAILS: false,
      REQUIRE_CUSTOMER_TITLE: false,
      REQUIRE_PHONE_NUMBER: true,
      REQUIRE_SIGNUP_DATASET: true,
      ASK_LOAN_QUESTIONS: true,
      COPY: {
        ...DEFAULT_COPY,
        ...{
          GET_STARTED: "We'll use this information to work out your options.",
          SIGN_UP_DISCLAIMER:
            "By creating an account, I agree to Ahauz's [TERMS_LINK] and [PRIVACY_POLICY_LINK].",
          ADDRESS_LABELS: {
            LINE_1: "Pincode",
            LINE_2: "Flat, House no., Building, Apartment",
            LINE_3: "Area, Street, Sector, Village",
            TOWN_CITY: "Town/City",
            COUNTY: "State",
          },
          VALUE_LABELS: {
            MORTGAGE_BALANCE: "Home Loan Balance",
          },
          COPYRIGHT: "Copyright © Ahauz.com",
        },
      },
      LINKS: {
        TERMS: "https://ahauz.com/in-terms/",
        PRIVACY: "https://ahauz.com/in-privacy-policy/",
      },
    },
  },
};
