import { gql } from "@apollo/client";

import {
  FRAGMENT_CUSTOMER_TYPE,
  FRAGMENT_EQUITYCHECK_TYPE,
  FRAGMENT_RESPONSE_FIELDS,
  FRAGMENT_VERIFICATION_CHECK_TYPE,
} from "api/fragments";

export const QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN = gql`
  query AccountValidateResetPasswordToken($token: String!, $uidb64: String!) {
    accountValidateResetPasswordToken(token: $token, uidb64: $uidb64) {
      ...ResponseFields
    }
  }
  ${FRAGMENT_RESPONSE_FIELDS}
`;

export const QUERY_AUTHENTICATED_USER = gql`
  query Customer {
    customer {
      ...CustomerFields
      equityCheck {
        ...EquityCheckFields
      }
      verificationFail
      verificationCheck {
        ...VerificationCheckFields
      }
    }
  }
  ${FRAGMENT_CUSTOMER_TYPE}
  ${FRAGMENT_EQUITYCHECK_TYPE}
  ${FRAGMENT_VERIFICATION_CHECK_TYPE}
`;

export const QUERY_VALIDATE_ACCOUNT_USER_MAGIC_LOGIN = gql`
  query AccountValidateMagicLogin($magic: String!, $email: String) {
    response: accountValidateMagicLogin(magic: $magic, email: $email) {
      ...ResponseFields
    }
  }
  ${FRAGMENT_RESPONSE_FIELDS}
`;
