import { useLazyQuery, useMutation } from "@apollo/client";
import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { MUTATION_LOGIN } from "api/mutations/account";
import { QUERY_VALIDATE_ACCOUNT_USER_MAGIC_LOGIN } from "api/queries/account";

import { trackEvent, trackPage } from "analytics";
import AlertSuccess from "components/Alert/Success";
import AlertWarning from "components/Alert/Warning";
import AuthScreenBase from "components/Auth/AuthScreenBase";
import Form from "components/Form";
import FormInputEmail from "components/Form/Input/Email";
import FormInputPassword from "components/Form/Input/Password";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import useUrlQuery from "hooks/useUrlQuery";
import EmailOnlyLogin from "pages/Login/components/EmailOnlyLogin";
import * as consts from "pages/Login/consts";
import * as types from "pages/Login/index.types";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";

export default function Login() {
  let urlParams = useParams();
  let urlQuery = useUrlQuery();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true);
  const [loadingTimeout, setLoadingTimeout] = useState<any>(null);
  const [emailOnlyLogin, setEmailOnlyLogin] = useState<boolean>(false);
  const [emailOnlyLoginFail, setEmailOnlyLoginFail] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Array<string> | null>(null);
  const [formData, setFormData] = useState<types.FormDataType>({
    email: null,
    password: null,
  });

  let userUUID = urlParams?.magic;
  let utmSource = urlQuery.get("utm_source");
  let utmMedium = urlQuery.get("utm_medium");

  const [validateMagicLogin, { error: queryError, data: queryData }] =
    useLazyQuery(QUERY_VALIDATE_ACCOUNT_USER_MAGIC_LOGIN);

  const [
    login,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_LOGIN);

  useEffect(() => {
    trackPage("Login");
  }, []);

  /**
   * On load look for magic uuid param and attempt to validate this user
   */
  useEffect(() => {
    if (userUUID) {
      // Only perform this logic for retargeting campaign users
      if (utmSource && utmSource.toLowerCase() === "retargeting" && utmMedium) {
        if (utmMedium.toLowerCase() === "email") {
          // Email source, we can straight up attemt to auto login
          validateMagicLogin({
            variables: {
              magic: userUUID,
            },
          });
        } else if (utmMedium.toLowerCase() === "whatsapp") {
          // Whatsapp source, proceed to verifying users email then attempt to login
          setEmailOnlyLogin(true);
          setLoadingDashboard(false);
        }
      }
    } else {
      setLoadingDashboard(false);
    }
  }, [userUUID, utmSource, utmMedium, validateMagicLogin]);

  /**
   * If we have a successful user look up, redirect to dashbord
   * with authenticated user, else show login form
   */
  useEffect(() => {
    if (queryData?.response) {
      if (queryData.response?.ok) {
        trackEvent("Magic Login: Success");
        setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
      } else {
        trackEvent("Magic Login: Fail");
        if (emailOnlyLogin) {
          setEmailOnlyLogin(false);
          setEmailOnlyLoginFail(true);
        }
        setTimeout(() => setLoadingDashboard(false), 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  useEffect(() => {
    if (queryError) {
      setLoadingDashboard(false);
    }
  }, [queryError]);

  /**
   * Handle mutation response:
   * - Redirect to dashboard on success
   * - Render errors on fail
   */
  useEffect(() => {
    if (mutationData?.accountLogin) {
      trackEvent("Manual Login: Success");

      const dashboardRedirect = APP_URLS.DASHBOARD;

      setTimeout(
        () => (window.location.href = dashboardRedirect),
        loadingDashboard ? 0 : 2000
      );

      return;
    }

    let errors = [];

    if (mutationError || (mutationData && !mutationData?.accountLogin)) {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
        setLoadingTimeout(null);
      }
      setLoadingDashboard(false);
      errors.push(
        `Invalid email or password, please try again or contact our Customer Support team on ${AHAUZ_PHONE_NUMBER}.`
      );
    }

    setFormErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationError, mutationData]);

  /**
   * Check if user can submit form dependant on error state
   */
  useEffect(() => {
    const canSubmit = !!(formData.email && formData.password);
    setCanSubmit(canSubmit);
  }, [formData, formErrors]);

  const handleEmailOnlyLogin = (email: string) => {
    setLoadingDashboard(true);
    validateMagicLogin({
      variables: {
        magic: userUUID,
        email: email,
      },
    });
  };

  /**
   * On input change, reset any form data and reset form data
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetMutation();
    setFormErrors(null);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  /**
   * On form submit, reset any form errors and trigger submission
   * @param event
   */
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    resetMutation();
    setFormErrors(null);

    const timeoutId = setTimeout(() => {
      setLoadingDashboard(true);
    }, 3000);
    setLoadingTimeout(timeoutId);

    login({
      variables: {
        username: formData.email,
        password: formData.password,
      },
    });
  };

  /**
   * Render success message when login success
   * @returns ReactElement
   */
  const renderLoginSuccess = (): ReactElement => (
    <AlertSuccess title={consts.COPY_LOGIN_SUCCESS_TITLE} shadow>
      <p>
        We'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  /**
   * Determine which component to render based on API query result
   * @returns ReactElement
   */
  const renderLogin = (): ReactElement => {
    if (mutationData?.accountLogin) {
      return renderLoginSuccess();
    }

    return (
      <PageContainer>
        <>
          <Form
            onSubmit={handleFormSubmit}
            errors={formErrors}
            canSubmit={canSubmit}
            submitCTA={consts.COPY_LOGIN_SUBMIT}
            submitting={mutationLoading}
          >
            <>
              {emailOnlyLoginFail && (
                <AlertWarning>
                  <p>
                    We can't automatically send you to your Dashboard right now,
                    please try and login using your email and password.
                  </p>
                </AlertWarning>
              )}
              <FormInputEmail onChange={handleInputChange} />
              <FormInputPassword
                validate={false}
                onChange={handleInputChange}
              />
            </>
          </Form>
        </>
      </PageContainer>
    );
  };

  if (loadingDashboard) {
    return (
      <PageDialogScreen logo={false}>
        <>
          <Loading large />
          <p className="mt-6 text-center">Loading your dashboard...</p>
        </>
      </PageDialogScreen>
    );
  }

  if (emailOnlyLogin) {
    return <EmailOnlyLogin submitEmailOnlyLogin={handleEmailOnlyLogin} />;
  }

  return (
    <AuthScreenBase
      title="Login"
      subtitle="Sign in below to view, track and release your home equity."
      signup
    >
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="px-2 sm:mx-auto sm:w-full sm:max-w-md sm:px-0">
          {renderLogin()}
          <div className="mt-8 text-center text-base text-brand-copy-light">
            Forgot your password?{" "}
            <a href={APP_URLS.ACCOUNT.FORGOT_PASSWORD}>
              Click here to reset it
            </a>
          </div>
        </div>
      </div>
    </AuthScreenBase>
  );
}
