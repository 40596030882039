import ActionCTA from "components/ActionCTA";
import CaptureIncome from "components/EquityCheck/Actions/components/CaptureIncome";
import ProductCTAs from "components/EquityCheck/Actions/components/ProductCTAs";
import { CRM } from "settings";
import { useUserStore } from "store/User";
import { EquityCheckType } from "ts/types/equitycheck";

export default function EquityCheckActions({
  equityCheck,
}: {
  equityCheck: EquityCheckType;
}) {
  const { IS_BETA } = useUserStore((state) => state.userCountry);
  const user = useUserStore((state) => state.user);
  const householdIncome = user?.detail?.householdIncome;

  const renderBetaMessage = () => (
    <ActionCTA name="Talk to us" bookACall={false}>
      <p>
        It's taking a bit longer for us to assess your options. Please look out
        for an email from us.
      </p>
    </ActionCTA>
  );

  // const renderAutoRemortgage = () => (
  //   <AutoRemortgageProducCTA equityCheck={equityCheck} filter={[]} />
  // );

  const renderCaptureIncome = () => {
    // Equity Release ok for Customers without income
    if (equityCheck.productEquityRelease) {
      return renderLoanActions(["productEquityRelease"]);
    }

    return <CaptureIncome />;
  };

  const renderCreditIssues = () => {
    // Equity Release ok for Customers with Credit Issues
    if (equityCheck.productEquityRelease) {
      return renderLoanActions(["productEquityRelease"]);
    }

    return (
      <ActionCTA
        name="Credit improvements"
        bookingLink={CRM.APPOINTMENT_BOOKING_URL}
        bookACall={false}
      >
        <>
          <p>
            Rebuilding credit? Some lenders may assist. We're here to discuss
            your situation.
          </p>
          <p>
            Missed payment defaults may impact credit access. If you find
            yourself in this situtation, always speak to your lenders or seek
            free debt advice.
          </p>
        </>
      </ActionCTA>
    );
  };

  const renderLoanActions = (filter: string[] = []) => (
    <ProductCTAs equityCheck={equityCheck} filter={filter} />
  );

  const renderLowEquity = () => (
    <ActionCTA
      name="Talk to us"
      bookingLink={CRM.APPOINTMENT_BOOKING_URL}
      bookACall={false}
    >
      <p>
        As your mortgage balance reduces and your house value rises, your home
        equity will increase too.
        <br />
        <span className="mt-2 block">
          An increase in equity could enable you to make home improvements,
          consolidate debts or buy a new home.
        </span>
      </p>
    </ActionCTA>
  );

  const renderActionCTAs = () => {
    if (IS_BETA) {
      return renderBetaMessage();
    }

    // if (
    //   equityCheck.productAutoRemortgage &&
    //   equityCheck?.live?.propertyMortgagePercent > 0
    // ) {
    //   return renderAutoRemortgage();
    // }

    if (!householdIncome) {
      return renderCaptureIncome();
    }

    if (equityCheck?.live?.hasCreditIssues) {
      return renderCreditIssues();
    }

    if (
      [
        equityCheck.productAutoRemortgage,
        equityCheck.productBuyProperty,
        equityCheck.productDebtConsolidation,
        equityCheck.productEquityLoan,
        equityCheck.productRemortgage,
        equityCheck.productEquityRelease,
        equityCheck.productMortgageProtection,
      ].some((val) => val)
    ) {
      return renderLoanActions();
    }

    return renderLowEquity();
  };

  return renderActionCTAs();
}
